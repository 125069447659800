const reportGeneratorActions = {
  FETCH_REPORT_START: 'FETCH_REPORT_START',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  FETCH_REPORT_FAILURE: 'FETCH_REPORT_FAILURE',

  FETCH_REPORT_START_ROMA: 'FETCH_REPORT_START_ROMA',
  FETCH_REPORT_ROMA_SUCCESS: 'FETCH_REPORT_ROMA_SUCCESS',
  FETCH_REPORT_ROMA_FAILURE: 'FETCH_REPORT_ROMA_FAILURE',

  FETCH_REPORT_CURRENT_START: 'FETCH_REPORT_CURRENT_START',
  FETCH_REPORT_CURRENT_SUCCESS: 'FETCH_REPORT_CURRENT_SUCCESS',
  FETCH_REPORT_CURRENT_FAILURE: 'FETCH_REPORT_CURRENT_FAILURE',

  FETCH_REPORT_YESTERDAY_START: 'FETCH_REPORT_YESTERDAY_START',
  FETCH_REPORT_YESTERDAY_SUCCESS: 'FETCH_REPORT_YESTERDAY_SUCCESS',
  FETCH_REPORT_YESTERDAY_FAILURE: 'FETCH_REPORT_YESTERDAY_FAILURE',

  FETCH_FIN_REPORT_START: 'FETCH_FIN_REPORT_START',
  FETCH_FIN_REPORT_FILTERS_START: 'FETCH_FIN_REPORT_FILTERS_START',
  FETCH_FIN_REPORT_SUCCESS: 'FETCH_FIN_REPORT_SUCCESS',
  FETCH_FIN_REPORT_SUCCESS_CURRENT: 'FETCH_FIN_REPORT_SUCCESS_CURRENT',
  FETCH_FIN_REPORT_FAILURE: 'FETCH_FIN_REPORT_FAILURE',
  SET_FIN_REPORT_DATA: 'SET_FIN_REPORT_DATA',
  FETCH_FIN_REPORT_FILTERS_START_CURRENT:
    'FETCH_FIN_REPORT_FILTERS_START_CURRENT',

  FETCH_FIN_REPORT_PAYOUT: 'FETCH_FIN_REPORT_PAYOUT',
  FETCH_FIN_REPORT_PAYOUT_SUCCESS: 'FETCH_FIN_REPORT_PAYOUT_SUCCESS',
  FETCH_FIN_REPORT_PAYOUT_FAILURE: 'FETCH_FIN_REPORT_PAYOUT_FAILURE',

  FETCH_FIN_REPORT_PAYOUT_DETAIL: 'FETCH_FIN_REPORT_PAYOUT_DETAIL',
  FETCH_FIN_REPORT_PAYOUT_DETAIL_SUCCESS:
    'FETCH_FIN_REPORT_PAYOUT_DETAIL_SUCCESS',
  FETCH_FIN_REPORT_PAYOUT_DETAIL_FAILURE:
    'FETCH_FIN_REPORT_PAYOUT_DETAIL_FAILURE',

  fetchReportStart: (request, resId) => ({
    type: reportGeneratorActions.FETCH_REPORT_START,
    payload: request,
  }),
  fetchReportSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_REPORT_SUCCESS,
    payload: report,
  }),
  fetchReportFailure: (error) => ({
    type: reportGeneratorActions.FETCH_REPORT_FAILURE,
    payload: error,
  }),
  fetchReportStartRoma: (request, resId) => ({
    type: reportGeneratorActions.FETCH_REPORT_START_ROMA,
    payload: { request, resId },
  }),
  fetchReportStartRomaSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_REPORT_ROMA_SUCCESS,
    payload: report,
  }),
  fetchReportStartRomaFailure: (error) => ({
    type: reportGeneratorActions.FETCH_REPORT_ROMA_FAILURE,
    payload: error,
  }),
  fetchReportYesterdayStart: (request) => ({
    type: reportGeneratorActions.FETCH_REPORT_YESTERDAY_START,
    payload: request,
  }),
  fetchReportYesterdaySuccess: (report) => ({
    type: reportGeneratorActions.FETCH_REPORT_YESTERDAY_SUCCESS,
    payload: report,
  }),
  fetchReportYesterdayFailure: (error) => ({
    type: reportGeneratorActions.FETCH_REPORT_YESTERDAY_FAILURE,
    payload: error,
  }),
  fetchReportCurrentStart: (request) => ({
    type: reportGeneratorActions.FETCH_REPORT_CURRENT_START,
    payload: request,
  }),
  fetchReportCurrentSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_REPORT_CURRENT_SUCCESS,
    payload: report,
  }),
  fetchReportCurrentFailure: (error) => ({
    type: reportGeneratorActions.FETCH_REPORT_CURRENT_FAILURE,
    payload: error,
  }),

  fetchFinReportStart: (restaurantId) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_START,
    payload: restaurantId,
  }),
  fetchFinReportFiltersStart: (filters) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START,
    payload: filters,
  }),
  fetchFinReportFiltersStartCurrent: (filters) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START_CURRENT,
    payload: filters,
  }),
  setFinReportData: (payload) => ({
    type: reportGeneratorActions.SET_FIN_REPORT_DATA,
    payload: payload,
  }),
  fetchFinReportSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS,
    payload: report,
  }),
  fetchFinReportSuccessCurrent: (report) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS_CURRENT,
    payload: report,
  }),
  fetchFinReportFailure: (error) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_FAILURE,
    payload: error,
  }),
  fetchFinReportPayout: (filters) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT,
    payload: filters,
  }),
  fetchFinReportPayoutSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_SUCCESS,
    payload: report,
  }),
  fetchFinReportPayoutFailure: (error) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_FAILURE,
    payload: error,
  }),
  fetchFinReportPayoutDetail: (filters) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL,
    payload: filters,
  }),
  fetchFinReportPayoutDetailSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL_SUCCESS,
    payload: report,
  }),
  fetchFinReportPayoutDetailFailure: (error) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL_FAILURE,
    payload: error,
  }),
};

export default reportGeneratorActions;
