export default {
  siteName: 'ORDERBYTE',
  siteIcon: 'ion-android-cloud-outline',
  version: '0.3.145',
  footerText: `Orderbyte @ ${new Date().getFullYear()} Created by Orderbyte. Version 0.3.145`,

  enableAnimatedRoute: false,
  //apiUrl: 'http://deliverybrands.ru/api/v1',
  //apiWSUrl: 'ws://deliverybrands.ru/wsapi/v1',
  //   apiUrl: 'http://127.0.0.1:35000',
  // apiWSUrl: 'ws://127.0.0.1:35000/wsapi/v1',
  apiUrl: 'https://orderbyte.io/api/v1',
  apiWSUrl: 'wss://orderbyte.io/wsapi/v1',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
