import actions from './actions';
import moment from 'moment';
import Notification from '@iso/components/Notification';

const INITIAL_DATA = {
  data: null,
  partnerSettings: null,
  brandSettings: null,
  loadingPartnerSettings: true,
  automationSettings: null,
  loading: true,
  isLoading: false,
  error: null,
  loading: false,
  error: null,
  stripeUrl: null,
  checklistStatus: null,
  updateChecklistStatus: null,
  isLoadingOnboard: false,
  subscriptionData: null,
  guardRailData: null,
  referralList: null,
  referralLoading: false,
  referralConditionList: null,
  referralConditionLoading: false,
  telegramLoading: false,
  paymentLoading: false,
};
export default function accountSettingsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.FETCH_RKEEPER_SETTINGS_SUCCESS:
    case actions.SUBMIT_RKEEPER_SETTINGS_SUCCESS:
      return {
        ...state,
        rKeeperSettings: action.payload,
        loading: false,
        isLoading: false,
        error: null,
      };
    case actions.FETCH_PRINTER_INTEGRATION_SUCCESS:
      // case actions.SUBMIT_RKEEPER_SETTINGS_SUCCESS:
      return {
        ...state,
        printerSettings: action.payload,
        loading: false,
        isLoading: false,
        error: null,
      };
    case actions.CONNECT_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.CONNECT_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        stripeUrl: action.payload,
      };
    case actions.CONNECT_STRIPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.FETCH_REFERRAL_LIST:
      return {
        ...state,
        referralLoading: true,
        error: null,
      };
    case actions.FETCH_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        referralLoading: false,
        referralList: action.payload,
      };
    case actions.FETCH_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        referralLoading: false,
        error: action.payload.error,
      };

    case actions.FETCH_REFERRAL_CONDITION_LIST:
      return {
        ...state,
        referralConditionLoading: true,
        error: null,
      };
    case actions.FETCH_REFERRAL_CONDITION_LIST_SUCCESS:
      return {
        ...state,
        referralConditionLoading: false,
        referralConditionList: action.payload,
      };
    case actions.FETCH_REFERRAL_CONDITION_LIST_FAILURE:
      return {
        ...state,
        referralConditionLoading: false,
        error: action.payload.error,
      };

    case actions.DELETE_REFERRAL_RESTAURANT_SUCCESS:
      return {
        ...state,
        referralConditionList: state.referralConditionList.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actions.DELETE_REFERRAL_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        referralConditionList,
      };

    case actions.CREATE_REFERRAL_RESTAURANT_SUCCESS:
      return {
        ...state,
        referralConditionList: [...state.referralConditionList, action.payload],
      };
    case actions.CREATE_REFERRAL_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        referralConditionList,
      };
    case actions.CREATE_TELEGRAM_SETTING:
      return {
        ...state,
        telegramLoading: true,
        error: null,
      };
    case actions.CREATE_TELEGRAM_SETTING_SUCCESS:
      return {
        ...state,
        telegramLoading: false,
      };
    case actions.CREATE_TELEGRAM_SETTING_FAILURE:
      return {
        ...state,
        telegramLoading: false,
        error: action.payload.error,
      };
    case actions.CREATE_PAYMENT_SETTING:
      return {
        ...state,
        paymentLoading: true,
        error: null,
      };
    case actions.CREATE_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
      };
    case actions.CREATE_PAYMENT_SETTING_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        error: action.payload.error,
      };
    case actions.FETCH_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.FETCH_SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionData: action.payload,
      };
    case actions.FETCH_SUBSCRIPTION_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actions.FETCH_FETCH_GUARD_RAIL_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        guardRailData: action.payload,
      };
    case actions.FETCH_GUARD_RAIL_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actions.FETCH_CHECKLIST_STATUS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.FETCH_CHECKLIST_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checklistStatus: action.payload,
      };
    case actions.FETCH_CHECKLIST_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_CHECKLIST_STATUS:
      return {
        ...state,
        isLoadingOnboard: true,
        error: null,
      };
    case actions.UPDATE_CHECKLIST_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingOnboard: false,
        updateChecklistStatus: action.payload,
      };
    case actions.UPDATE_CHECKLIST_STATUS_FAILURE:
      return {
        ...state,
        isLoadingOnboard: false,
        error: action.payload.error,
      };
    case actions.FETCH_RKEEPER_SETTINGS_FAILURE:
    case actions.SUBMIT_RKEEPER_SETTINGS_FAILURE:
    case actions.FETCH_IIKO_SETTINGS_FAILURE:
    case actions.FETCH_POS_INTEGRATION_FAILURE:
    case actions.FETCH_PRINTER_INTEGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        isLoading: false,
        error: action.payload,
      };
    case actions.FETCH_BRAND_SETTINGS_SUCCESS: {
      return {
        ...state,
        brandSettings: action.payload.brandSettings,
        isLoading: false,
        error: null,
      };
    }
    case actions.FETCH_AUTOMATION_SETTINGS_SUCCESS:
    case actions.SUBMIT_AUTOMATION_SETTINGS_SUCCESS: {
      console.log('SUBMIT_AUTOMATION_SETTINGS_SUCCESS', action);
      return {
        ...state,
        automationSettings: {
          ...action.payload.automationSettings,
        },
        isLoading: false,
      };
    }
    case actions.FETCH_PARTNER_SETTINGS_SUCCESS:
    case actions.SUBMIT_PARTNER_SETTINGS_SUCCESS: {
      const settings = {
        ...action.payload.partnerSettings,
        date: moment(new Date(action.payload.partnerSettings.date)),
      };
      return {
        ...state,
        partnerSettings: settings,
        loadingPartnerSettings: false,
        error: null,
      };
    }
    case actions.FETCH_PARTNER_SETTINGS_FAILURE:
    case actions.SUBMIT_PARTNER_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.SUBMIT_PARTNER_PLAN_START:
    case actions.SUBMIT_CREATE_SITE_START:
    case actions.SUBMIT_CREATE_MENU_START:
    case actions.SUBMIT_FILL_BRAND_START:
    case actions.SUBMIT_SITE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUBMIT_PARTNER_PLAN_SUCCESS:
    case actions.SUBMIT_CREATE_SITE_SUCCESS:
    case actions.SUBMIT_CREATE_MENU_SUCCESS:
    case actions.SUBMIT_FILL_BRAND_SUCCESS:
    case actions.SUBMIT_SITE_ADDRESS_SUCCESS:
    case actions.SUBMIT_SITE_ADDRESS_DOMAIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        partnerSettings: {
          ...state.partnerSettings,
          ...action.payload.data,
        },
      };
    case actions.SUBMIT_PARTNER_PLAN_FAILURE:
    case actions.SUBMIT_CREATE_SITE_FAILURE:
    case actions.SUBMIT_CREATE_MENU_FAILURE:
    case actions.SUBMIT_FILL_BRAND_FAILURE:
    case actions.SUBMIT_SITE_ADDRESS_FAILURE:
      Notification(
        'error',
        'Что-то пошло не так :(',
        'Не удалось выполнить действие, данные не сохранены!'
      );
      return {
        ...state,
        loading: false,
        isLoading: false,
        error: action.payload,
      };

    case actions.FETCH_IIKO_SETTINGS_SUCCESS:
    case actions.SUBMIT_IIKO_SETTINGS_SUCCESS:
      return {
        ...state,
        iikoSettings: action.payload.iikoSettings,
        loading: false,
        isLoading: false,
        error: null,
      };
    case actions.FETCH_POS_INTEGRATION_SUCCESS:
      return {
        ...state,
        posIntegration: action.payload.posIntegration,
        loading: false,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}
