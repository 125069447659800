import reportGeneratorActions from './actions';

const INITIAL_DATA = {
  data: null,
  dataRoma: null,
  currentData: null,
  yesterdayData: null,
  finData: null,
  finDataCurrent: null,
  loading: true,
  loadingRoma: true,
  currentLoading: true,
  yesterdayLoading: true,
  payoutData: null,
  payoutLoading: false,
  payoutDetailedData: null,
  payoutDetailedLoading: false,
  error: null,
  recordData: null,
};
export default function stopListsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case reportGeneratorActions.FETCH_REPORT_START:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case reportGeneratorActions.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_REPORT_START_ROMA:
      return {
        ...state,
        dataRoma: null,
        loadingRoma: true,
      };
    case reportGeneratorActions.FETCH_REPORT_ROMA_SUCCESS:
      return {
        ...state,
        dataRoma: action.payload,
        loadingRoma: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_REPORT_ROMA_FAILURE:
      return {
        ...state,
        loadingRoma: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_REPORT_CURRENT_START:
      return {
        ...state,
        currentData: null,
        currentLoading: true,
      };
    case reportGeneratorActions.FETCH_REPORT_CURRENT_SUCCESS:
      return {
        ...state,
        currentData: action.payload,
        currentLoading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_REPORT_CURRENT_FAILURE:
      return {
        ...state,
        currentLoading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_REPORT_YESTERDAY_START:
      return {
        ...state,
        yesterdayData: null,
        yesterdayLoading: true,
      };
    case reportGeneratorActions.FETCH_REPORT_YESTERDAY_SUCCESS:
      return {
        ...state,
        yesterdayData: action.payload,
        yesterdayLoading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_REPORT_YESTERDAY_FAILURE:
      return {
        ...state,
        yesterdayLoading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START:
      return {
        ...state,
        finData: null,
        loading: true,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START_CURRENT:
      return {
        ...state,
        finDataCurrent: null,
        loading: true,
        error: null,
      };
    case reportGeneratorActions.SET_FIN_REPORT_DATA:
      return {
        ...state,
        recordData: action.payload,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS:
      return {
        ...state,
        finData: action.payload,
        loading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS_CURRENT:
      return {
        ...state,
        finDataCurrent: action.payload,
        loading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT:
      return {
        ...state,
        payoutData: null,
        payoutLoading: true,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_SUCCESS:
      return {
        ...state,
        payoutData: action.payload,
        payoutLoading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_FAILURE:
      return {
        ...state,
        payoutLoading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL:
      return {
        ...state,
        payoutDetailedData: null,
        payoutDetailedLoading: true,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL_SUCCESS:
      return {
        ...state,
        payoutDetailedData: action.payload,
        payoutDetailedLoading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_PAYOUT_DETAIL_FAILURE:
      return {
        ...state,
        payoutDetailedLoading: false,
        payoutDetailedData: null,
        error: action.payload,
      };
    default:
      return state;
  }
}
