import siteConfig from '@iso/config/site.config';
import Notification from '@iso/components/Notification';
import React from 'react';

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_ERROR: 'REGISTRATION_ERROR',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  registration: (values) => {
    return (dispatch) => {
      return actions.regJWT(values).then(
        (result) => {
          if (result.Token) {
            localStorage.setItem('id', result.Id);
            localStorage.setItem('uuid', result.uuid);
            localStorage.setItem('user', result.Login);
            localStorage.setItem('userRole', 'nonconfirm');
            localStorage.setItem('id_token', result.Token);
            dispatch({
              type: actions.REGISTRATION_SUCCESS,
              token: result.Token,
              userRole: 'guest',
              profile: 'Profile',
            });
            return result;
          } else {
            dispatch({
              type: actions.REGISTRATION_ERROR,
            });
            return Promise.reject(new Error('Registration error'));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };
  },
  forgotPassword: (values) => {
    return (dispatch) => {
      return actions.forgotPasswordRequest(values).then(
        (result) => {
          Notification(
            'success',
            'На почту было отправлено письмо для восстановления доступа!'
          );
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
    };
  },
  login: (values) => {
    return (dispatch) => {
      return actions.authJWT(values.name, values.password).then(
        (result) => {
          if (result.Token) {
            localStorage.setItem('id', result.Id);
            localStorage.setItem('uuid', result.uuid);
            localStorage.setItem('user', result.Login);
            localStorage.setItem('userRole', result.UserRole);
            localStorage.setItem('id_token', result.Token);
            localStorage.setItem('notices', true);
            localStorage.setItem('subs', result.subs);
            if (result?.restaurantId) {
              localStorage.setItem('restaurantId', result.restaurantId);
            }
            dispatch({
              type: actions.LOGIN_SUCCESS,
              token: result.Token,
              userRole: result.UserRole,
              profile: 'Profile',
            });
            return result;
          } else {
            dispatch({
              type: actions.LOGIN_ERROR,
            });
            return Promise.reject(
              new Error('Login error - user does not exist')
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };
  },
  logout: () => ({
    type: actions.LOGOUT,
  }),
  async options() {
    const response = fetch(`${siteConfig.apiUrl}`, {
      crossDomain: true,
      method: 'OPTIONS',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response;
  },

  async authJWT(name, password) {
    const response = await fetch(`${siteConfig.apiUrl}/login`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({ Login: name, Password: password }),
    });

    return await response.json();
  },

  async forgotPasswordRequest(values) {
    values.login = values.email;
    const response = await fetch(`${siteConfig.apiUrl}/send/remind`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(values),
    });
    const text = await response.text();
    if (text === '') {
      return {};
    }
    return JSON.parse(text);
  },
  async regJWT(values) {
    values.login = values.email;
    const response = await fetch(`${siteConfig.apiUrl}/registration`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(values),
    });

    return await response.json();
  },
  async logoutJWT() {
    const header = 'Bearer ' + localStorage.getItem('id_token');
    console.log(header);
    return await fetch(`${siteConfig.apiUrl}/logout`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'X-Authorization:': header,
      },
      mode: 'cors',
      body: JSON.stringify({}),
    });
  },
};
export default actions;
